<template>
  <div>
    {{ this.field.Value }}
    <b-form-checkbox v-model="checked" name="check-button" switch>
      Switch Checkbox <b>(Checked: {{ checked }})</b>
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    value() {
      return this.field.Value;
    },
  },
};
</script>

<style></style>
